<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-card elevation="0" outlined>
      <v-card-text class="ma-0 pa-0">
        <v-card elevation="0">
          <v-card-title class="pa-0 ma-0">
            <v-row>
              <v-col class="pt-2 ma-0" cols="12">
                <div class="card-header-tab card-header">
                  <div
                    class="card-header-title font-size-lg text-capitalize font-weight-normal"
                  >
                    <b-input-group width="100%">
                      <b-form-input
                        :class="[this.$vuetify.theme.dark? 'dark-bg' : '']"
                        v-model="search"
                        :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        :placeholder="$t('datatables.lang_search')"
                        @focus="showTouchKeyboard"
                      />
                    </b-input-group>
                  </div>
                  <div class="btn-actions-pane-right actions-icon-btn">
                    <b-dropdown
                      no-caret
                      right
                      toggle-class="btn-icon btn-icon-only"
                      :menu-class="[this.$vuetify.theme.dark? 'dark-bg' : '']"
                      variant="link"
                    >
                      <span slot="button-content"
                        ><i class="pe-7s-menu btn-icon-wrapper"></i
                      ></span>
                      <div>
                        <h6 class="dropdown-header" tabindex="-1">
                          {{ this.$t("generic.lang_Options") }}
                        </h6>
                        <button
                          :disabled="selectedRows.length < 1"
                          class="dropdown-item"
                          tabindex="0"
                          type="button"
                          @click="deleteEntries()"
                        >
                          <v-icon class="dropdown-icon" color="red">
                            mdi-delete
                          </v-icon>
                          <span>{{ $t("generic.lang_delete") }}</span>
                        </button>
                      </div>
                    </b-dropdown>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6" md="6" lg="6">
                <date-picker
                  dense
                  @input="getDataFromApi"
                  v-model="filterFields.startDate.value"
                  :label="$t('generic.lang_from')"
                  hide-details
                  outlined
                  clearable
                >
                </date-picker>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <date-picker
                  dense
                  @input="getDataFromApi"
                  v-model="filterFields.endDate.value"
                  :label="$t('generic.lang_till')"
                  hide-details
                  outlined
                  clearable
                >
                </date-picker>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  @change="getDataFromApi"
                  v-model="filterFields.itemIDs.value"
                  hide-details
                  :label="$t('erp.lang_posItemName')"
                  :filter="itemsFilter"
                  chips
                  clearable
                  clear-icon="mdi-close"
                  multiple
                  outlined
                  :items="filteredItems"
                  item-value="id"
                  item-text="name"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-autocomplete
                  dense
                  @change="getDataFromApi"
                  v-model="filterFields.customer.value"
                  hide-details
                  :label="$t('customers.lang_customers')"
                  clearable
                  clear-icon="mdi-close"
                  outlined
                  :items="showCustomers"
                  item-value="full_name"
                  item-text="full_name"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  dense
                  v-model="filterFields.reference.value"
                  hide-details
                  outlined
                  :label="$t('generic.lang_reference')"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-divider class="ma-1" />
          <v-data-table
            v-model="selectedRows"
            :footer-props="footerProps"
            :headers="filteredDatatableHeaders"
            :items="filteredDatatableEntries"
            :loading="loading"
            :options.sync="pagination"
            :server-items-length="total"
            :show-select="true"
            footer-props.items-per-page-text="Einträge pro Seite"
          >
            <template v-slot:item.total="{ item }">
              {{ item.total | currency }}
            </template>
            <template v-slot:item.date="{ item }">
              {{ getDate(item.date) }}
            </template>
            <template v-slot:item.customer="{ item }">
              <template
                v-if="
                  item.customer && typeof JSON.parse(item.customer) === 'object'
                "
              >
                <p class="mb-0" v-if="JSON.parse(item.customer).company">
                  {{ JSON.parse(item.customer).company }}
                </p>
                <p
                  class="mb-0"
                  v-if="
                    JSON.parse(item.customer).first_name ||
                    JSON.parse(item.customer).last_name
                  "
                >
                  {{
                    JSON.parse(item.customer).first_name &&
                    JSON.parse(item.customer).last_name
                      ? JSON.parse(item.customer).first_name +
                        " " +
                        JSON.parse(item.customer).last_name
                      : JSON.parse(item.customer).first_name ||
                        JSON.parse(item.customer).last_name
                  }}
                </p>
                <p class="mb-0" v-if="JSON.parse(item.customer).street">
                  {{ JSON.parse(item.customer).street }}
                </p>
                <p
                  class="mb-0"
                  v-if="
                    JSON.parse(item.customer).city ||
                    JSON.parse(item.customer).zip
                  "
                >
                  {{
                    JSON.parse(item.customer).city &&
                    JSON.parse(item.customer).zip
                      ? JSON.parse(item.customer).city +
                        " " +
                        JSON.parse(item.customer).zip
                      : JSON.parse(item.customer).city ||
                        JSON.parse(item.customer).zip
                  }}
                </p>
              </template>
              <template v-else>
                {{ item.customer }}
              </template>
            </template>

            <template v-slot:item.crud="{ item }">
              <v-row align="center" justify="center">
                <v-btn
                  class="mx-auto"
                  color="primary"
                  icon
                  text
                  @click="editEntry(item)"
                >
                  <v-icon>edit</v-icon>
                </v-btn>

                <v-btn
                  class="mx-auto"
                  color="error"
                  icon
                  text
                  @click="deleteEntry(item)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </v-card>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
          <vue-touch-keyboard
            v-if="touchKeyboard.visible"
            id="onScreenKeyboard"
            :accept="hideTouchKeyboard"
            :cancel="hideTouchKeyboard"
            :defaultKeySet="touchKeyboard.keySet"
            :input="touchKeyboard.input"
            :layout="touchKeyboard.layout"
            :options="touchKeyboard.options"
            class="internalWidthExpanded"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns";
import moment from "moment";
import { mapState } from "vuex";
import DatePicker from "@/components/common/datepicker";

export default {
  name: "QuotationsComponent",
  components: { DatePicker },
  mixins: [mixin],
  data() {
    return {
      datatableHeaders: [
        /*
         * ('uuid', 'quotationId', 'customerId', 'dateTimeStamp', 'total','reference', 'sellerId', 'invoiceCreated', 'ascInvoiceUUID');
         * */

        {
          text: "UUID",
          align: "left",
          hide: true,
          value: "id",
        },
        {
          text: "ID",
          align: "left",
          value: "quotationID",
        },
        {
          text: this.$t("generic.lang_customer"),
          align: "left",
          value: "customer",
        },
        {
          text: this.$t("generic.lang_date"),
          align: "left",
          value: "date",
        },
        {
          text: this.$t("generic.lang_total"),
          align: "left",
          value: "total",
        },
        {
          text: this.$t("generic.lang_ref"),
          align: "left",
          value: "ref",
        },
        {
          text: this.$t("generic.lang_seller"),
          align: "left",
          value: "seller",
        },
        {
          text: "invoiceCreated",
          align: "left",
          hide: true,
          value: "seller",
        },
        {
          text: "ascInvoiceUUID",
          align: "left",
          hide: true,
          value: "seller",
        },
        {
          text: "items",
          align: "left",
          hide: true,
          value: "items",
        },
        {
          text: "",
          value: "crud",
          sortable: false,
          width: "230",
          align: "end",
        },
      ],
      loading: false,
      selectedRows: [],
      awaitingSearch: null,
      dataToShow: [],
      search: "",
      total: 0,
      pagination: {},
      footerProps: { "items-per-page-options": [15, 30, 50, 100] },
      rowsPerPageItems: [10, 20, 50, 100],
      id: null,
      filterFields: {
        itemIDs: { index: 9, value: [] },
        reference: { index: 5, value: null },
        startDate: { index: "start_date", value: null },
        endDate: { index: "end_date", value: null },
        customer: { index: 2, value: null },
      },
      showCustomers: [],

      filteredItems: [],
    };
  },
  computed: {
    filteredDatatableHeaders: function () {
      let headers = [];

      this.datatableHeaders.forEach((header) => {
        if (header.hasOwnProperty("hide")) {
          if (header.hide) {
            return;
          }
        }
        headers.push(header);
      });
      return headers;
    },
    filteredDatatableEntries: function () {
      let dataToRender = [];

      this.dataToShow.forEach((data) => {
        let row = {};

        this.datatableHeaders.forEach((header, index) => {
          if (!(header.hidden && header.hidden == true)) {
            row[header.value] = data[index];

            /*  if (header.value === "certificate") {
                header.value = "status";
              }*/

            if (header.value === "status") {
              row[header.value] = data[index - 1];
              if (row[header.value] == "2") {
                row[header.value] = true;
              } else {
                row[header.value] = false;
              }
            }
            if (header.value === "startDate") {
              row[header.value] = moment(
                data[index],
                "hh:mm:ss DD loc.MM.YY"
              ).format("YYYY-MM-DD");
            }
          }
        });

        dataToRender.push(row);
      });

      return dataToRender;
    },
  },

  watch: {
    search: function () {
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getDataFromApi();
        }
      }, 500);
    },
    "filterFields.reference.value": function () {
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getDataFromApi();
        }
      }, 500);
    },
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    selectedRows: function () {
      this.$emit("changeSelectedRows", this.selectedRows);
    },
  },
  methods: {
    itemsFilter(item, queryText) {
      return (
        item.name?.toLowerCase().includes(queryText?.toLowerCase()) ||
        item.ean?.toLowerCase().includes(queryText?.toLowerCase())
      );
    },
    editEntry(entry) {
      this.$router.push({
        name: "billing.quotations.edit",
        params: { uuid: entry.id },
      });
    },
    deleteData(idsToDelete = []) {
      let self = this;
      this.loading = true;
      this.$swal({
        title: this.$t("accounting.lang_delete_quotationHead"),
        text: this.$t("accounting.lang_delete_quotationBody"),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(this.id);
          }
          this.axios
            .post(ENDPOINTS.BILLING.QUOTATIONS.DELETE, {
              quotationUUID: idsToDelete,
            })
            .then((res) => {
              if (res.data.success) {
                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_ActionSuccessful"),
                  color: "success",
                });
                self.getDataFromApi();
                self.resetSelectedRows();
              } else {
                Events.$emit("showSnackbar", {
                  message:
                    res.data.msg || this.$t("generic.lang_errorOccurred"),
                  color: "error",
                });
              }
              this.loading = false;
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
    deleteEntry(entry) {
      this.id = entry.id;
      this.deleteData();
    },
    deleteEntries() {
      let ids = [];
      this.selectedRows.forEach((row) => {
        ids.push(row.id);
      });
      this.deleteData(ids);
    },
    resetSelectedRows() {
      this.selectedRows = [];
    },
    getDate(timeStamp) {
      return moment.unix(timeStamp).format("DD.MM.YY HH:mm");
    },
    getDataFromApi() {
      this.loading = true;
      this.selectedRows = [];

      let params = {
        sEcho: 1,
        iDisplayStart:
          this.pagination.itemsPerPage * (this.pagination.page - 1),
        iDisplayLength: this.pagination.itemsPerPage,
      };

      //SORTING / PAGINATION / SEARCHING
      if (this.search) {
        params.sSearch = this.search;
      }
      params["iSortingCols"] = 1;
      if (this.pagination.sortBy) {
        //FIND COL. INDEX
        let index = this.datatableHeaders.findIndex(
          (header) => header.value === this.pagination.sortBy[0]
        );

        if (index < 0) {
          index = 0;
        }

        params["iSortCol_0"] = index;
        params["bSortable_" + index] = "true";
        params["sSortDir_0"] = this.pagination.sortDesc[0] ? "desc" : "asc";
      }
      if (!this.pagination.sortBy[0]) {
        params["iSortCol_0"] = 3;
        params["bSortable_" + 3] = "true";
        params["sSortDir_0"] = "desc";
      }

      //PUT ADDITIONAL PARAMS HERE
      //params['customerID'] = parseInt(this.$props.customerId);
      if (this.filterFields.startDate.value) {
        params[this.filterFields.startDate.index] = moment(
          this.filterFields.startDate.value
        )
          .startOf("days")
          .unix();
      }
      if (this.filterFields.endDate.value) {
        params[this.filterFields.endDate.index] = moment(
          this.filterFields.endDate.value
        )
          .endOf("days")
          .unix();
      }

      for (const key in this.filterFields) {
        if (this.filterFields[key].value) {
          if (!isNaN(parseInt(this.filterFields[key].index))) {
            params["bSearchable_" + this.filterFields[key].index] = "true";
            params["sSearch_" + this.filterFields[key].index] =
              this.filterFields[key].value;
          }
        }
      }

      this.axios
        .get(ENDPOINTS.DATATABLES.BILLING.QUOTATIONS, {
          params: params,
        })
        .then((res) => {
          this.total = parseInt(res.data.iTotalDisplayRecords);
          //let data=res.data.aaData;
          this.dataToShow = res.data.aaData;
          this.loading = false;
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });

          this.dataToShow = [];
        });
    },
    loadCustomers() {
      //AXIOS CALL
      this.axios
        .get(ENDPOINTS.ERP.CUSTOMER.GET, {
          params: {
            search: "",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.results.length > 0) {
              this.showCustomers = res.data.results;
            } else {
              this.showCustomers = [];
            }
          }
        })
        .catch(() => {});
    },
  },
  async mounted() {
    this.getDataFromApi();
    this.loadCustomers();

    // GET ITEMS
    this.filteredItems = await this.$store.dispatch(
      "items/getActiveItemsSortByNameAsc"
    );
  },
};
</script>
